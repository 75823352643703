import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {ApolloClient, ApolloProvider, createHttpLink} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import {cache} from "./store/cache";
import App from './components/app/App';
import {GRAPHQL_ENDPOINT, JWT_KEY} from "./config";
import {setContext} from "@apollo/client/link/context";

const typeDefs = '';

const httpLink2 = new BatchHttpLink({
    uri: GRAPHQL_ENDPOINT,
    credentials: 'include',
    batchMax: 2,
    batchInterval: 1000,
});

const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
    credentials: 'include',
});

const authLink = setContext((_, {headers}) => {
    const token = localStorage.getItem(JWT_KEY);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const client = new ApolloClient({
    uri: GRAPHQL_ENDPOINT,
    cache: cache,
    typeDefs,
    link: authLink.concat(httpLink),
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>,
    document.getElementById('root'),
);
