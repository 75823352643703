import {InMemoryCache} from "@apollo/client";
import {currentUser, isLoggedIn, pdfVariables, whiteLabel} from "./localState";

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn: {
                    read() {
                        return isLoggedIn();
                    },
                },
                whiteLabel: {
                    read() {
                        return whiteLabel();
                    },
                },
                currentUser: {
                    read() {
                        return currentUser();
                    },
                },
                pdfVariables: {
                    read() {
                        return pdfVariables();
                    },
                },
                actionCodes: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                campaignLinks: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                campaigns: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
            },
        },
    },
});
