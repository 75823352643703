import React from "react";
import './Sidebar.css';

import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

import Links from "./links/Links";
import Header from "./header/Header";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle";
import {makeStyles} from "@material-ui/core/styles";
import ParticlesComponent from "../general-components/particles-component/ParticlesComponent";
import {drawerWidth} from "../../assets/jss/material-dashboard-react";

const useStyles = makeStyles(styles);
const SideBar = ({toggleSidebar, isOpen}) => {
    const classes = useStyles();
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={"left"}
                    open={!isOpen}
                    onClose={toggleSidebar}
                    ModalProps={{
                        keepMounted: false, // Better open performance on mobile.
                    }}
                >
                    <Header headerText={'Daten Vorsprung'}/>
                    <div className={classes.sidebarWrapper}>
                        <Links toggleMobile={toggleSidebar}/>
                    </div>
                    <div
                        className={classes.background}
                        style={{
                            backgroundImage: "url('https://www.absolut-ticket.at/wp-content/uploads/2021/04/system-3699542_1920-1.jpg')",
                        }}
                    />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    open
                    className={classes.drawerPaper}
                    style={{width: drawerWidth}}
                >
                    <div style={{
                        position: "relative",
                        display: "inline-block",
                        backgroundColor: "#000000",
                        height: "100%",
                        width: drawerWidth,
                        overflow: "hidden",
                    }}>
                        <ParticlesComponent enableOnHover={false} numberOfParticles={20} width={drawerWidth}
                                            height={"100vh"}/>
                        <div style={{
                            position: "absolute",
                            top: 0, zIndex: "120",
                            height: "100vh",
                            width: drawerWidth,
                            overflow: "hidden",
                        }}>
                            <Header headerText={'Daten Vorsprung'}/>
                            <Links />
                        </div>

                    </div>
                </Drawer>
            </Hidden>
        </div>
    );
};


export default SideBar;
