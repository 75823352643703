import {CircularProgress} from "@material-ui/core";
import {Typography} from "@material-ui/core";

const Loading = ({withoutText = false}) => {
    return (
        <div style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "100",
        }}>
            <CircularProgress size={90}/>
            {withoutText &&
                <Typography style={{top: 40}}>
                    Loading...
                </Typography>}
        </div>
    );
};

export default Loading;