import React, {lazy, Suspense, useCallback, useEffect, useState} from 'react';
import {Card} from "reactstrap";
import {BrowserRouter as Router} from "react-router-dom";
import './App.styles.css';

import styles from "../../assets/jss/material-dashboard-react/layouts/adminStyle";
import {makeStyles} from "@material-ui/core/styles";
import {useQuery} from "@apollo/client";
import {IS_LOGGED_IN} from "../../graphql/queries/state/IS_LOGGED_IN";
import {ToastContainer} from "react-toastify";
import Sidebar from "../sidebar/Sidebar";
import Loading from "../general-components/loading-component/Loading";
import Grid from "@material-ui/core/Grid";
import GridItem from "../general-components/grid-container/GridItem";
import 'react-toastify/dist/ReactToastify.css';
import {currentUser, isLoggedIn, whiteLabel as whiteLabelState} from "../../store/localState";

const Navigation = lazy(() => import('../../navigation/Navigation'));
const TopBar = lazy(() => import("../topbar/TopBar"));

const useStyles = makeStyles(styles);

const EVENTS = ['click', 'load', 'scroll'];

const App = () => {
        const classes = useStyles();
        const [sidebarIsOpen, setSidebarOpen] = useState(true);
        const {data: {isLoggedIn: isLoggedInQuery, whiteLabel}} = useQuery(IS_LOGGED_IN);
        const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

        let logOutAction = useCallback(() => {
            if (currentUser()) {
                //check if cookie exp date is older than 24h
                if (new Date().getTime() - new Date(currentUser().exp) > (24 * 60 * 60 * 1000)) {
                    localStorage.clear();
                    isLoggedIn(false);
                }
            }
        }, []);

        useEffect(() => {
            if (!whiteLabel) {
                EVENTS.forEach((event) => {
                    window.addEventListener(event, logOutAction);
                });
            }
        }, [logOutAction, whiteLabel]);

        useEffect(() => {
            if (!window.location.pathname.includes("white-label")) {
                whiteLabelState(false);
            }
        }, [whiteLabel]);

        return (
            <Router>
                {isLoggedInQuery && !whiteLabel && <Sidebar toggleSidebar={toggleSidebar} isOpen={sidebarIsOpen}/>}
                <Suspense fallback={
                    <Grid container>
                        <GridItem md={3}>
                        </GridItem>
                        <GridItem md={9}>
                            <Loading/>
                        </GridItem>
                    </Grid>
                }>
                    <div className={classes.wrapper}>
                        {
                            isLoggedInQuery ?
                                <>
                                    <div className={!whiteLabel ? classes.mainPanel : null}>
                                        {isLoggedInQuery && !whiteLabel &&
                                        <TopBar toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen}/>}
                                        <div style={{display: "flex"}} id="scrollableDiv">
                                            <Card id="containerCard" style={{height: "auto", minHeight: "80vh"}}>
                                                <Navigation/>
                                            </Card>
                                        </div>
                                    </div>
                                </>
                                : <Navigation/>
                        }
                        <ToastContainer
                            position="bottom-center"
                            autoClose={3000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </Suspense>
            </Router>
        );
    }
;
export default App;