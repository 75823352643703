import React from "react";
import styles from "../../../assets/jss/material-dashboard-react/components/sidebarStyle";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const Header = ({headerText}) => {
    const classes = useStyles();

    return (
        <div className={classes.logo}>
            <div className={classNames(classes.logoLink)}
                 style={{marginLeft: "10%", marginRight: "10%", marginTop: "5%"}}>
                <h3>{headerText}</h3>
            </div>
        </div>
    );
};

export default Header;