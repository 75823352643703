import Particles from "react-particles-js";

const ParticlesComponent = ({enableOnHover = false, numberOfParticles = 30, width = '100%', height = '100vh'}) => {
    return (
        <Particles
            style={{
                backgroundColor: "#000000",
            }}
            width={width}
            height={height}
            params={{
                polygon: {
                    enable: true,
                    type: 'inside',
                    move: {
                        radius: 3,
                    },
                },
                particles: {
                    number: {
                        value: numberOfParticles,
                    },
                    size: {
                        value: 2,
                    },
                    move: {
                        speed: 1.3,
                    },
                    color: {
                        value: "#005769C2",
                    },
                    shape: {
                        stroke: {
                            color: "#005769C2",
                        },
                    },
                    line_linked: {
                        color: "#005769C2",
                        opacity: 1,
                        width: 2,
                    },
                },
                interactivity: {
                    events: {
                        onhover: {
                            enable: enableOnHover,
                            mode: "repulse",
                        },
                    },
                    "modes": {
                        "grab": {
                            "distance": 300,
                            "line_linked": {
                                "opacity": 0.7,
                            },
                        },
                        "bubble": {
                            "distance": 300,
                            "size": 10,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 1,
                        },
                        "repulse": {
                            "distance": 100,
                            "duration": 0.4,
                        },
                    },
                },
                // ...particlesConfig,
            }}
        />

    );
};

export default ParticlesComponent;