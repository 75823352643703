import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {NavLink} from "reactstrap";
import {currentUser} from "../../../store/localState";
import {Link, useLocation} from "react-router-dom";
import {
    BarChart, DesktopAccessDisabled, ImportExport, InsertChartTwoTone, PieChartOutlined, Settings,
} from "@material-ui/icons";
import styles from "../../../assets/jss/material-dashboard-react/components/sidebarStyle";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import {AddLink, Campaign} from "@mui/icons-material";
// import toggleSidebar from '../../app/App'
const useStyles = makeStyles(styles);

const Links = ({toggleMobile}) => {

    const classes = useStyles();
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        let routes = [{
            name: 'Admin',
            path: '/admin',
            icon: <Settings style={{margin: "2%"}}/>,
        }, {
            name: 'Campaigns',
            path: '/campaigns',
            icon: <Campaign style={{margin: "2%"}}/>,
        }, {
            name: 'Actions',
            path: '/actions',
            icon: <AddLink style={{margin: "2%"}}/>,
        }, {
            name: 'Exclude IP Ranges',
            path: '/iprange',
            icon: <DesktopAccessDisabled style={{margin: "2%"}}/>,
        }, {
            name: 'Data',
            path: '/data',
            icon: <ImportExport style={{margin: "2%"}}/>,
        }, {
            name: 'Overall Analysis',
            path: '/analysis',
            icon: <PieChartOutlined style={{margin: "2%"}}/>,
        }, {
            name: 'Pages Analysis',
            path: '/pageAnalysis',
            icon: <InsertChartTwoTone style={{margin: "2%"}}/>,
        },
        ];

        if (currentUser().client_groupType === 'EVENT_ORGANIZER') {
            routes = [...routes, {
                name: 'Events Analysis',
                path: '/eventAnalysis',
                icon: <BarChart style={{margin: "2%"}}/>,
            }];
        }

        setRoutes(routes);
    }, []);

    let location = useLocation();


    function activeRoute(routeName) {
        return location.pathname.includes(routeName);
    }

    return (

        <List className={classes.list}>

            {
                routes.map(({name, path, icon}, index) => {
                    let listItemClasses = classNames({
                        [" " + classes['blue']]: activeRoute(path),
                    });
                    let logOutItemClass = classNames({
                        [" " + classes["red"]]: true,
                    });
                    const whiteFontClasses = classNames({
                        [" " + classes.whiteFont]: activeRoute(path),
                    });

                    if (name === 'Admin') {
                        if (!(currentUser() && currentUser().role === 'admin')) {
                            return null;
                        }
                    }

                    return (
                        <NavLink tag={Link} onClick={toggleMobile} to={path} className={
                            (name === 'Log Out') ? classNames(classes.logOut, classes.item) : classes.item
                        } key={index}>
                            <ListItem button
                                      className={(name === 'Log Out') ? classes.itemLink + logOutItemClass : classes.itemLink + listItemClasses}>
                                <div className={classNames(classes.itemIcon, whiteFontClasses)}>
                                    {icon}
                                </div>
                                <ListItemText
                                    primary={name}
                                    className={classNames(classes.itemText, whiteFontClasses)}
                                    disableTypography={false}
                                />
                            </ListItem>
                        </NavLink>
                    );
                })
            }


        </List>);
};
export default Links;